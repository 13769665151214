import { create } from 'zustand';
import { persist, PersistOptions } from 'zustand/middleware';
import { THEME_TYPES } from '@/constants';

const { THEME_LIGHT, THEME_DARK } = THEME_TYPES;

type TFont = {
  heading: string;
  title: string;
  body: string;
  bodySmall: string;
  blogBody: string;
  footNote: string;
};

interface ThemeState {
  theme: string;
  toggleTheme: () => void;
  font: TFont;
  setFontHeading: (id: string) => void;
  category: string | null;
  setCategory: (value: string | null) => void;
  showToast: boolean;
  setShowToast: (value: boolean) => void;
  copyUrlToast: boolean;
  setCopyUrlToast: (value: boolean) => void;
}

// Define the persist options to satisfy TypeScript
const persistOptions: PersistOptions<ThemeState> = {
  name: 'theme',
};

const useThemeStore = create<ThemeState>()(
  persist(
    (set) => ({
      theme: THEME_LIGHT,
      toggleTheme: () =>
        set((state) => ({
          theme: state.theme === THEME_LIGHT ? THEME_DARK : THEME_LIGHT,
        })),
      font: {
        heading: 'heading-TextSmall',
        title: 'title-TextSmall',
        body: 'body-TextSmall',
        bodySmall: 'bodySmall-TextSmall',
        blogBody: 'blogBody-TextSmall',
        footNote: 'footNote-TextSmall',
      },
      setFontHeading: (id: string) => {
        let fontsize;
        switch (id) {
          case 'small':
            fontsize = {
              heading: 'heading-TextSmall',
              title: 'title-TextSmall',
              body: 'body-TextSmall',
              blogBody: 'blogBody-TextSmall',
              bodySmall: 'bodySmall-TextSmall',
              footNote: 'footNote-TextSmall',
            };
            break;
          case 'medium':
            fontsize = {
              heading: 'heading-TextMedium',
              title: 'title-TextMedium',
              body: 'body-TextMedium',
              blogBody: 'blogBody-TextMedium',
              bodySmall: 'bodySmall-TextMedium',
              footNote: 'footNote-TextMedium',
            };
            break;
          case 'large':
            fontsize = {
              heading: 'heading-TextLarge',
              title: 'title-TextLarge',
              body: 'body-TextLarge',
              blogBody: 'blogBody-TextLarge',
              bodySmall: 'bodySmall-TextLarge',
              footNote: 'footNote-TextLarge',
            };
            break;
        }
        set({ font: fontsize });
      },
      category: null,
      setCategory: (value: string | null) => set({ category: value }),
      showToast: false,
      setShowToast: (value: boolean) => set({ showToast: value }),
      copyUrlToast: false,
      setCopyUrlToast: (value: boolean) => set({ copyUrlToast: value }),
    }),
    persistOptions
  )
);

export default useThemeStore;
